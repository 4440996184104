import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/Clients/Clients')
    },
    {
      path: '/terminals',
      name: 'terminals',
      component: () => import('@/views/Terminal/Terminals')
    },
    {
      path: '/terminal_models',
      name: 'terminal_models',
      component: () => import('@/views/Terminal/TerminalModels')
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/Project/Projects')
    },
    {
      path: '/project/:id',
      name: 'project',
      component: () => import('@/views/Project/Project')
    },
    {
      path: '/firmware_packages',
      name: 'firmware_packages',
      component: () => import('@/views/FirmwarePackage/FirmwarePackages')
    },
    {
      path: '/shell',
      name: 'shell',
      props: true,
      component: () => import('@/views/Shell/Shell')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
